.wrapper {
  height: 100vh;
}

.section {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 100%;
  max-width: 66rem;
  margin: 0 auto;
  animation: slide-down 700ms ease-in-out forwards;
}

.left-container {
  flex: 1;
}

.left-container h1 {
  color: hsl(249, 100%, 94%);
  margin-block: 1.5rem;
  font-size: clamp(4rem, 6vw, 4.5rem);
  line-height: clamp(4.5rem, 6vw, 5rem);
  letter-spacing: -0.02rem;
  font-family: "League Spartan";
  font-weight: 600;
}

.left-container p {
  color: white;
  font-size: clamp(1.35rem, 2vw, 1.5rem);
  line-height: clamp(2.025rem, 2vw, 2.25rem);
  font-family: "League Spartan";
  font-weight: 300;
  margin-block: 0 1.5rem;
}

.white-text {
  font-weight: 400;
  color: white;
}

.low-opacity-text {
  opacity: 0.85;
}

.button-container {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
}

.button-container a {
  text-decoration: none;
  box-shadow: inset 0 2px 10px -5px rgba(255, 255, 255, 0.8),
    0 2px 10px rgba(0, 0, 0, 0.6);
  background: linear-gradient(to top, #6252c5, #8273e2);
  color: #fff;
  padding: 0.75em 1.5em;
  font-size: clamp(1.35rem, 2vw, 1.5rem);
  font-weight: 500;
  border-radius: 7px;
  font-family: "League Spartan";
  transition: 200ms;
  user-select: none;
}

.button-container a:hover {
  transform: scale(1.08);
}

.floating-arrow {
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  bottom: 0.85rem;
  left: 0;
  right: 0;
  margin-inline: auto;
  animation: floating 1.5s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-30%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 783px) {
  .wrapper {
    height: max-content;
  }
  
  .section {
    grid-template-columns: 1fr;
    margin-top: 61px;
    max-width: 37.5rem;
    row-gap: 5rem;
  }

  .left-container {
    text-align: center;
  }

  .button-container {
    justify-content: center;
  }

  .floating-arrow {
    bottom: -5rem;
  }
}
