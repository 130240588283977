.header {
  position: fixed;
  width: 100%;
  height: 62px;
  background: rgba(3, 0, 20, 0.75);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 100;
  padding-inline: 2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 66rem;
  height: 100%;
  margin: 0 auto;
  /* padding-block: 4px; */
  animation: navbar-slide 700ms ease-in-out forwards;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  user-select: none;
}

.logo svg {
  width: 1.5rem;
  height: 1.5rem;
}

.logo span {
  color: #fff;
  font-family: "League Spartan";
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 6px;
}

.links {
  list-style-type: none;
  display: flex;
  height: 100%;
  /* gap: 0.85rem; */
  padding-left: 0;
}

.links li {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.links li a {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 auto;
  padding-inline: 20px;
  /* height: 100%;
  line-height: 100%; */
  font-family: "League Spartan";
  font-size: 1.25rem;
  text-decoration: none;
  /* padding: 0.65rem 1rem; */
  /* line-height: 1; */
}

.links li a:hover {
  background: hsla(248, 66%, 67%, 0.7);
}

.links li a:active {
  background: hsla(248, 66%, 77%, 0.7);
  transition: none;
}

@keyframes navbar-slide {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}

@media screen and (max-width: 783px) {
  .links {
    gap: 1.5rem;
  }

  .links li a {
    font-size: 1.7rem;
  }
}
