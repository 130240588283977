.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 1000;
  transition: right 0.2s ease-in-out;
}

.sidebar.open__sidebar {
  height: 100vh;
  background: rgba(3, 0, 20, 0.85);
}

.hamburger-container {
  /* background: red; */
  margin-top: 6px;
  margin-right: 2rem;
  margin-left: 26px;
  display: flex;
  justify-content: flex-end;
}

.links {
  display: none;
  list-style-type: none;
  padding-left: 0;
  padding-block: 1rem;
  margin-block: 0;
  height: 100%;
}

.links.open__links {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.links li {
  border-bottom: solid 1px hsla(248, 66%, 80%, 0.2);
}

.links li:first-of-type {
  border-top: solid 1px hsla(248, 66%, 80%, 0.2);
}

.links li a {
  display: block;
  color: #fff;
  font-family: "League Spartan";
  font-size: 1.25rem;
  text-decoration: none;
  padding-block: 24px;
  cursor: pointer;
  text-align: center;
}

.links li a:hover {
  background: hsla(248, 60%, 65%, 0.7);
}

.links li a:active {
  background: hsla(248, 60%, 75%, 0.7);
}

@media screen and (max-width: 430px) {
  .links.open__links {
    width: 100vw;
    text-align: center;
  }

  .links li a {
    padding-left: 0;
  }
}
