.tools {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.2rem;
  padding-left: 0;
}

/* .tools {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4.9rem, 6.5rem));
  grid-template-rows: max-content;
  gap: 2.2rem;
  justify-content: space-between;
  padding-left: 0;
} */

.title {
  text-align: center;
}

/* @media (max-width: 858px) {
  .tools-list {
    justify-content: center;
  }
}

@media (min-width: 1200px) {
  .tools-list {
    justify-content: flex-start;
  }
} */