.container {
  position: relative;
  z-index: 1;
}

.svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gear {
  transform-origin: 300px 250px;
  animation: rotateGear 30s linear infinite;
}

.ring path {
  animation: glow 2.5s linear infinite;
}

@media screen and (max-width: 783px) {
  .container {
    margin-left: 25px;
  }
}

@media screen and (max-width: 535px) {
  .container {
    margin-left: 20px;
  }
}

@media screen and (max-width: 430px) {
  .container {
    margin-left: 17px;
  }
}

@media screen and (max-width: 375px) {
  .container {
    margin-left: 14px;
  }
}

@keyframes rotateGear {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes glow {
  0% {
    filter: drop-shadow(0 0 6px hsl(248, 100%, 82%));
    -webkit-filter: drop-shadow(0 0 6px hsl(248, 100%, 82%));
  }

  50% {
    filter: drop-shadow(0 0 10px hsl(248, 100%, 82%));
    -webkit-filter: drop-shadow(0 0 10px hsl(248, 100%, 82%));
  }

  100% {
    filter: drop-shadow(0 0 6px hsl(248, 100%, 82%));
    -webkit-filter: drop-shadow(0 0 6px hsl(248, 100%, 82%));
  }
}
