.section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  column-gap: 2.2rem;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 800ms, transform 800ms;
  filter: blur(100%);
}

.section.visible {
  opacity: 1;
  transform: translateX(0%);
  filter: blur(0%);
}

.section p {
  font-family: "League Spartan";
  font-weight: 300;
  color: white;
  font-size: 1.35rem;
  line-height: 2.025rem;
  margin: 0;
  opacity: 0.8;
}

.headshot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: clamp(20rem, 45vw, 26rem);
}

.headshot img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 
  0 0 20px -5px rgba(255, 255, 255, 0.75),
  0 0 200px rgba(98, 82, 197, 0.75);
}

@media screen and (max-width: 783px) {
  .section {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
}
