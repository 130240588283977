.container-grid {
  border-radius: 1rem;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  list-style-type: none;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 1.4s ease, transform 0.5s ease;
  filter: blur(1);
  display: flex;
  flex-direction: column;
  font-family: "League Spartan";
}

.visible {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
}

/* Add a delay to each div */
.container-grid:nth-child(1) {
  transition-delay: 0s;
}

.container-grid:nth-child(2) {
  transition-delay: 0.3s;
}

.container-grid:nth-child(3) {
  transition-delay: 0.6s;
}

.container-grid:nth-child(4) {
  transition-delay: 0.9s;
}

.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.below-video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: #353342;
  padding: 20px;
}

.project-title {
  color: white;
  font-size: clamp(1.35rem, 2vw, 1.5rem);
  /* font-size: 1.5rem; */
  font-weight: 600;
  margin-block: 0 5px;
  padding-block: 6px;
}

.tools-and-links-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tools-list {
  display: inline-flex;
  justify-content: center;
  gap: 7px;
  padding-left: 0;
  list-style-type: none;
}

.tools-list li {
  width: clamp(28px, 2.85vw, 34px);
  height: clamp(28px, 2.85vw, 34px);
  padding: clamp(4px, 2.85vw, 5px);
  border-radius: 6px;
  background-color: hsl(248, 13%, 13%);
  transition: 170ms;
}

.built-with {
  display: block;
  font-size: 0.85rem;
  color: white;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 4px;
}

.links-container {
  display: flex;
  gap: 10px;
}

/*Project Links*/
.links-container a {
  background: linear-gradient(to top, #6252c5, #8273e2);
  /* box-shadow: inset 0 2px 10px -6px rgba(255, 255, 255, 0.8); */
  border: solid 1px rgba(130, 115, 226, 0.35);
  padding: 0.75em 1.5em;
  color: white;
  border-radius: 7px;
  font-family: "League Spartan";
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  text-decoration: none;
  user-select: none;
  transition: 200ms;
}

.links-container a:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 783px) {
  .project-title {
    /* font-size: clamp(1.35rem, 3vw, 1.5rem); */
    font-size: 1.5rem;
  }

  .tools-list li {
    width: 34px;
    height: 34px;
    padding: 5px;
    /* width: clamp(28px, 6.4vw, 34px);
    height: clamp(28px, 6.4vw, 34px);
    padding: clamp(4px, 6.4vw, 5px); */
  }

  .links-container a {
    /* font-size: clamp(0.8rem, 3vw, 1rem); */
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .tools-and-links-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .links-container {
    width: 100%;
    justify-content: center;
  }

  .links-container a {
    flex: 1;
    text-align: center;
    padding-block: 1rem;
  }

  .links-container a:hover {
    transform: scale(1.05);
  }
}
