.section {
  display: flex;
  font-family: "League Spartan";
  font-weight: 300;
  font-size: 1.35rem;
  line-height: 2.025rem;
  transform: translateX(-50%);
  transition: opacity 800ms, transform 800ms;
  filter: blur(100%);
  gap: 2.2rem;
  margin-bottom: 3rem;
}

.section.visible {
  opacity: 1;
  transform: translateX(0%);
  filter: blur(0%);
}

.info-container {
  color: white;
  flex: 1;
}

.info-container p {
  opacity: 0.8;
  margin-block: 1rem;
}

@media screen and (max-width: 783px) {
  .section {
    flex-wrap: wrap;
    justify-content: center;
  }

  .info-container {
    flex-basis: 100%;
  }
}
