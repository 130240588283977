.container {
  width: 100%;
  margin: 0 auto 20px auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.all-fields-required {
  font-size: 0.85rem;
  color: hsl(248, 66%, 95%);
  font-weight: 400;
  letter-spacing: 2.5px;
  text-align: right;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

label {
  color: white;
  font-size: 1.22rem;
}

label,
input,
textarea {
  display: block;
}

input {
  width: 100%;
  padding: 15px;
  border-radius: 7px;
  border: solid 1px transparent;
  font-size: 1.35rem;
  font-family: "League Spartan";
  background: #353342;
  color: rgb(255, 255, 255, 0.8);
}

input:focus {
  outline: none;
  border-color: rgb(98, 82, 197);
  box-shadow: 0 0 15px rgba(98, 82, 197, 0.75);
  animation: bumpInput 300ms;
}

textarea {
  width: 100%;
  padding: 15px;
  border-radius: 7px;
  border: solid 1px transparent;
  font-size: 1.35rem;
  font-family: "League Spartan";
  resize: none;
  background: #353342;
  color: rgb(255, 255, 255, 0.8);
}

textarea:focus {
  outline: none;
  border-color: rgb(98, 82, 197);
  box-shadow: 0 0 15px rgba(98, 82, 197, 0.75);
}

.invalid,
input.invalid:focus,
textarea.invalid,
textarea.invalid:focus {
  outline: none;
  border-color: rgb(255, 77, 77);
  box-shadow: 0 0 15px rgb(255, 77, 77, 0.75);
}

.error-message {
  font-size: 1rem;
  margin-block: 10px 0;
  border-radius: 5px;
  color: rgb(255, 77, 77);
  background: rgba(255, 77, 77, 0.175);
  text-align: center;
}

.form-button {
  padding: 0.75em 1.5em;
  font-size: 1.35rem;
  font-weight: 500;
  font-family: "League Spartan";
  border-radius: 7px;
  border: none;
  background: linear-gradient(to top, #6252c5, #8273e2);
  color: #fff;
  cursor: pointer;
  margin-top: 12px;
  transition: 200ms;
  user-select: none;
  position: relative;
}

.form-button:hover {
  transform: scale(1.03);
}

.form-button:active {
  background: #6e5ddd;
  transition: none;
}

.form-button:disabled {
  opacity: 0.75;
}

.loading-spinner {
  height: 1.35rem;
  width: 1.35rem;
}

@keyframes bumpInput {
  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}
