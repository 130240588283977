.portfolio-grid {
  display: grid;
  gap: 2.2rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 1rem 0 0 0;
  margin: auto;
}

@media screen and (max-width: 783px) {
  .portfolio-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
