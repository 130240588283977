.link {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  user-select: none;
  color: hsl(249, 100%, 94%);
  font-family: "League Spartan";
  height: 100%;
}

.logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: inset 0 0 3px #fff, inset 2px 0 5px #7d6ee4,
    inset -2px 0 5px #9c91e0, inset 2px 0 20px #7d6ee4,
    inset -2px 0 20px #9c91e0, 0 0 4px #fff, -1px 0 5px #7d6ee4,
    1px 0 5px #9c91e0;
  animation: glow 5s linear infinite;
  transform: scale(0.7);
  margin-bottom: 3px;
}

.inner-text {
  position: absolute;
  top: 55%;
  left: 53%;
  transform: translate(-50%, -50%);
  font-size: 0.85rem;
  font-weight: 200;
}

.name {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

@keyframes glow {
  0% {
    box-shadow: inset 0 0 3px #fff, inset 2px 0 5px #7d6ee4,
      inset -2px 0 5px #9c91e0, inset 2px 0 20px #7d6ee4,
      inset -2px 0 20px #9c91e0, 0 0 9px #fff, -1px 0 10px #7d6ee4,
      1px 0 10px #9c91e0;
  }

  50% {
    box-shadow: inset 0 0 3px #fff, inset 2px 0 5px #7d6ee4,
      inset -2px 0 5px #9c91e0, inset 2px 0 20px #7d6ee4,
      inset -2px 0 20px #9c91e0, 0 0 20px #fff, -1px 0 21px #7d6ee4,
      1px 0 21px #9c91e0;
  }

  100% {
    box-shadow: inset 0 0 3px #fff, inset 2px 0 5px #7d6ee4,
      inset -2px 0 5px #9c91e0, inset 2px 0 20px #7d6ee4,
      inset -2px 0 20px #9c91e0, 0 0 9px #fff, -1px 0 10px #7d6ee4,
      1px 0 10px #9c91e0;
  }
}
