.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  letter-spacing: 0.035rem;
  font-family: "League Spartan";
}

.copyright {
  max-width: 40rem;
  margin: 2rem auto;
  color: #eee4ff;
  font-weight: 500;
}
