.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
}

.icon {
  height: 35px;
  width: 35px;
  transition: 200ms;
}

.icon:hover {
  transform: scale(1.1);
}

.icon:active {
  /* fill: #ccfffd; */
  transition: none;
}
