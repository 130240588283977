.message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 5px;
}

.sending {
  color: rgba(255, 255, 255, 0.8)
}

.send-successful {
  color: rgb(67, 195, 90);
  background: rgba(67, 195, 90, 0.175);
}

.send-failed {
  color: rgb(255, 77, 77);
  background: rgba(255, 77, 77, 0.175);
}
