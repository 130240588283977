.tool {
  list-style-type: none;
  width: clamp(4.8rem, 14vw, 6.5rem);
  font-family: "League Spartan";
  font-weight: 300;
  font-size: clamp(0.75rem, 2vw, 1rem);
  text-align: center;
  padding: 0.9em;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 300ms ease, transform 300ms ease;
  filter: blur(1);
  background-color: rgba(137, 121, 238, 0.065);
  border-radius: 10px;
}

.tool img {
  margin-bottom: 10px;
}

.tool span {
  color: white;
  opacity: 0.8;
}

.visible {
  opacity: 1;
  transform: translateX(0);
  filter: blur(0);
}

.tool:nth-child(1) {
  transition-delay: 0s;
}

.tool:nth-child(2) {
  transition-delay: 0.1s;
}

.tool:nth-child(3) {
  transition-delay: 0.2s;
}

.tool:nth-child(4) {
  transition-delay: 0.3s;
}

.tool:nth-child(5) {
  transition-delay: 0.4s;
}

.tool:nth-child(6) {
  transition-delay: 0.5s;
}

.tool:nth-child(7) {
  transition-delay: 0.6s;
}

.tool:nth-child(8) {
  transition-delay: 0.7s;
}

.tool:nth-child(9) {
  transition-delay: 0.8s;
}

.tool:nth-child(10) {
  transition-delay: 0.9s;
}

.tool:nth-child(11) {
  transition-delay: 1s;
}

.tool:nth-child(12) {
  transition-delay: 1.1s;
}

.tool:nth-child(13) {
  transition-delay: 1.2s;
}
